// Создаем текст сообщений для событий
// strings = {
//   'connected': '[sys][time]%time%[/time]: Вы успешно соединились к сервером как [user]%name%[/user].[/sys]',
//   'userJoined': '[sys][time]%time%[/time]: Пользователь [user]%name%[/user] присоединился к чату.[/sys]',
//   'messageSent': '[out][time]%time%[/time]: [user]%name%[/user]: %text%[/out]',
//   'messageReceived': '[in][time]%time%[/time]: [user]%name%[/user]: %text%[/in]',
//   'userSplit': '[sys][time]%time%[/time]: Пользователь [user]%name%[/user] покинул чат.[/sys]'
// };
// window.onload = function() {
//   // Создаем соединение с сервером; websockets почему-то в Хроме не работают, используем xhr
//   if (navigator.userAgent.toLowerCase().indexOf('chrome') != -1) {
//     socket = io.connect('http://localhost:3030', {'transports': ['xhr-polling']});
//   } else {
//     socket = io.connect('http://localhost:3030');
//   }
//   socket.on('connect', function () {
//     socket.on('message', function (msg) {
//       // Добавляем в лог сообщение, заменив время, имя и текст на полученные
//       document.querySelector('#log').innerHTML += strings[msg.event].replace(/\[([a-z]+)\]/g, '<span class="$1">').replace(/\[\/[a-z]+\]/g, '</span>').replace(/\%time\%/, msg.time).replace(/\%name\%/, msg.name).replace(/\%text\%/, unescape(msg.text).replace('<', '&lt;').replace('>', '&gt;')) + '<br>';
//       // Прокручиваем лог в конец
//       document.querySelector('#log').scrollTop = document.querySelector('#log').scrollHeight;
//     });
//     // При нажатии <Enter> или кнопки отправляем текст
//     document.querySelector('#input').onkeypress = function(e) {
//       if (e.which == '13') {
//         // Отправляем содержимое input'а, закодированное в escape-последовательность
//         socket.send(escape(document.querySelector('#input').value));
//         // Очищаем input
//         document.querySelector('#input').value = '';
//       }
//     };
//     document.querySelector('#send').onclick = function() {
//       socket.send(escape(document.querySelector('#input').value));
//       document.querySelector('#input').value = '';
//     };
//   });
// };
jQuery(function($){
  $("input[name=phone]").mask("+7 (799) 999-99-99");

	$("#summa").ionRangeSlider({
	    min: 5000,
	    max: 180000,
	    step: 5000,
	    from: 20000,
	    onChange: function (data) {
	    	$('.summa').html(data.from_pretty);
	    }
	});

	$("#period").ionRangeSlider({
	    min: 5,
	    from: 10,
	    step: 1,
	    max: 30,
	    postfix: ' дней',
	    onChange: function (data) {
	    	$('.day').html(data.from);
	    }
	});

	$('#login #submit').on('click', function(e) {
		var btn = $(this);
		if($('#phone').val() == '') {
			$('#phone').parent('.form-group').addClass('has-error');
			return fasle;
		}else {
			$('#phone').parent('.form-group').removeClass('has-error');
		}
		$.ajax({
      url: btn.data('url'),
      data: {
      	'phone' : $('#phone').val(),
      	'summa' : $('#summa').val(),
      	'period' : $('#period').val()
      },
      type: 'post',
      dateType: 'json',
      beforeSend: function() {
      	btn.prop('disabled', true);
      },
      success: function(data) {
        btn.prop('disabled', false);
        if(data.message) $('#login .message-box').html(data.message);
      },
      error: function(err){
      	btn.prop('disabled', false);
      	if(err.message) $('#login .message-box').html(err.message);
      }
  	});
	});

	$('#login').on('show.bs.modal', function(e) {
		if(e.relatedTarget.id == 'get') {
			$('#login #submit').data('url', '/request');
		}else {
			$('#login #submit').data('url', '/login');
		}
	});

  function animate(el, x) {
    $(el).removeClass(x + ' animated').addClass(x + ' animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
      $(this).removeClass(x + ' animated');
    });
  };

  function animateSmile() {
    var text = $('.progress-bar > span').html();
    $('.progress-bar').animate({
      width: "100%",
    }, {
      duration: 3000,
      step: function( step ){
      }
    }, function() {
      $('.progress-bar > span').html('<b style="color: rgb(255, 255, 255);line-height: 40px;font-size: 10px !important;">Цель достигнута!</b>');
      var timeoutSmale = setTimeout(function() {
        $('#smale').removeClass('hidden');
        $('.progress').addClass('hidden');
        $('.progress-bar').css('width', '0%');
        $('.progress-bar > span').html(text);
      }, 1000);
      var timeoutReset = setTimeout(function() {
        clearInterval(timeoutSmale);
        $('#smale').addClass('hidden');
        $('.progress').removeClass('hidden');
        animateSmile();
      }, 3000);
    });
  };

	$('#fullpage').fullpage({
		controlArrows: false,
		//events
		onLeave: function(index, nextIndex, direction){},
		afterLoad: function(anchorLink, index){
			if(index === 2) {
        animate('.btn-create-team', 'shake');
			}
		},
		afterRender: function(){},
		afterResize: function(){},
		afterResponsive: function(isResponsive){},
		afterSlideLoad: function(anchorLink, index, slideAnchor, slideIndex){
      if(index == 4 && slideIndex == 2) {
        animateSmile();
      }
    },
		onSlideLeave: function(anchorLink, index, slideIndex, direction, nextSlideIndex){}
	});

	$(".btn-create-team").on('click',function() {
		$.fn.fullpage.moveSlideRight();
	});

	$(".btn-next").on('change',function() {
		$.fn.fullpage.moveSlideRight();
	});

	$(".btn-our-team").on('click',function() {
		$.fn.fullpage.moveTo(7);
	});
});

var LC = LC || {}

LC.goal = new function() {
	calculate = function(){
	}

	var okn = function(expression) {
    switch(expression) {
        case 8:
        case 7:
        case 6:
        case 2:
            var str = 'ой';
            break;
        case 3:
            var str = 'ий';
            break;
        default:
            var str = 'ый';
    }
    return str;
  }

  getMonth = function() {
  	var installmentId = $('.btn-installment-group label.active input').data('id');
  	var terId = $('.btn-month-group label.active input').data('id');
  	$.ajax({
      url: '/api/goal/options',
      data: {
      	'installment_id' : installmentId,
      	'term_id' : terId
      },
      type: 'post',
      dateType: 'json',
      success: function(data) {
      	var el = '';
      	for (var i = 0; i < data.length; i++) {
					var date = moment(data[i].date);
      		el += '<label class="btn btn-default"> \
      				<input type="radio" name="month" data-rateid="'+data[i].term_rate_id+'" data-sum="'+data[i].sum+'" value="'+data[i].date+'">\
      				'+date.format('MMMM')+'</label>';
      	}
      	$('.btn-check-month-group').html(el);
      	$('.btn-check-month-group .btn').button();
      	$('.btn-check-month-group label:first-child').click();
      }
  	});
  }

  setMonth = function() {
  	var date = $('.btn-check-month-group label.active input').data('date');
  	var sum = $('.btn-check-month-group label.active input').data('sum');
  	var termRateId = $('.btn-check-month-group label.active input').data('rateid');
  	$('.sum > b').html(digit(sum));
  	date = moment(date);
  	$('.sum > span').html(date.format('DD MMMM YYYY'));
  	$('input#termId-'+ termRateId).click();
  	$('.progress-bar > span').html('<b>'+date.format('DD')+'</b>'+date.format('MMMM'));
    setRate();
  }

  setRate = function() {
    var rates = $('.btn-month-group label.active input').data('rate');
    rates.sort(
      function(a,b) {
        return (a.term > b.term) ? 1 : ((b.term > a.term) ? -1 : 0);
      }
    );
    var rate = $('.btn-check-month-group label.active input').data('rateid');
    rate -= 1;
  	var el = '';
  	for (var i = 0; i < rates.length; i++) {
  		var active = (i == rate) ? 'active' : '';
  		var checked = (i == rate) ? 'checked' : '';
  		el += '<label class="btn btn-default '+ active +'"> \
	  		<input type="radio" name="rate" id="termId-'+ rates[i].id +'" value="'+ rates[i].term +'" data-rate="'+ rates[i].rate +'" '+ checked +'  /> \
  			'+rates[i].term + '-' + okn(rates[i].term) +'</label>';
  	}
  	$("#month-group").html(el);
  	$('.btn-group-box .btn').button();
  	setSum();
  }

  digit = function (number) {
    var number =  String(number).replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ');
    return number;
  }

  setSum = function() {
  	var monthCount = $('.btn-month-group label.active input').val();
    $(".month-info-text").html('надо собрать '+ (monthCount / 2 - 1) +' друзей');
		var term = $('#month-group label.active input').val();
  	var rate = $('#month-group label.active input').data('rate');
  	var installment = $(".btn-installment-group").find('label.active input').val();
    var sumInDay = parseInt(parseFloat(installment)/30);
    $(".installment-info-text").html(sumInDay + 'т/день');
    var sum = (parseInt(monthCount)-1) * parseInt(installment);
  	var rateSum = (parseInt(sum) * parseFloat(rate)) / 100;
  	var info = 'Ваши взносы: '+ digit(installment) +' т * '+ (parseInt(monthCount-1)) +' мес = ' + digit(sum) + ' т. <br> Сервисный сбор в Fingram = ' + digit(rateSum) + ' т.';
  	$('#term-info').html(info);
  	var sum = sum - rateSum;
  	$('.sum > b').html(digit(sum));
  }

  setGoal = function() {
  	$('.bg-phone-slide-3 .sum i ').html($(this).val());
  }

  this.init = function(){
  	moment.locale('ru');
  	$(document).on('change', '.btn-month-group input', function() {
      $('.btn-month-group label').removeClass('active');
      $('.btn-month-group input[data-id='+$(this).data('id')+']').parent('label').addClass('active');
      $('.btn-month-group input[data-id='+$(this).data('id')+']').prop('checked', true);
      setRate();
    });
  	$(document).on('change', '.btn-installment-group input', function() {
      $('.btn-installment-group label').removeClass('active');
      $('.btn-installment-group input[data-id='+$(this).data('id')+']').parent('label').addClass('active');
      $('.btn-installment-group input[data-id='+$(this).data('id')+']').prop('checked', true);
      setRate();
    });
    $(document).on('change', '.btn-group-box input', setSum);
  	$(document).on('change', '#month-group label input', setSum);
  	$(document).on('change', '.btn-check-month-group input', setMonth);
  	$(document).on('keyup', 'input[name=goal]', setGoal);
  	getMonth();
    setRate();
  }

}

jQuery(document).ready(function () {
  LC.goal.init();
});